+function ($) {
	
/* ================================
 * Javascript Events for Global Nav
 ================================ */
 'use strict';

$(document).ready( function() {

	// Varialbles
	var $nav = $('#globalNav'),
			$button = $('#menuToggleBtn'),
			show = 'show';

	$button.on('click', function (e) {
		$nav.toggleClass(show);
	});
});

}(jQuery);